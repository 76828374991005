import React from "react";
import { Layout } from "../../layouts/Layout";
import { ContactAreaInner } from "../../components/ContactAreas/ContactAreaInner";
import { TeamAreaThree } from "../../components/TeamAreas/TeamAreaThree";


const ContactPage = () => {
  return (
    <Layout breadcrumb={"Contáctanos"} title={"Contáctanos"} header={4} footer={3}>
      {/* contact-area */}
      <ContactAreaInner />
       {/* team-area */}
       <TeamAreaThree />
    </Layout>
  );
};

export default ContactPage;
