import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServiceSeven } from "../../components/Services/ServiceSeven";

const ServicesPage = () => {
  return (
    <Layout breadcrumb={"Servicios"}  title={'Nuestros Servicios'} header={4} footer={3}>
      {/* services */}
      <ServiceSeven />
    </Layout>
  );
};

export default ServicesPage;
