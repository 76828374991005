import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

export const NavMenus = () => {
  const { pathname } = useLocation();

  const isActiveCn = (path) => {
    return path === pathname ? "active" : "";
  };

  
  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {/* home */}
        <li
          className={cn(isActiveCn("/"))}>
          <a href="/">Inicio</a>
        </li>
        {/* About */}
        <li className={cn(isActiveCn("/about"))}>
          <Link to="/about">Nosotros</Link>
        </li>
        {/* Services */}
        <li className={cn(isActiveCn("/services"))}>
          <Link to="/services">Servicios</Link>
        </li>

        {/* Contact */}
        <li className={cn(isActiveCn("/contact"))}>
          <Link to="/contact">Contáctanos</Link>
         </li>
       
      </ul>
    </div>
  );
};
