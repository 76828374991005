import { AboutFour } from "../../components/About/AboutFour";
import { BannerThree } from "../../components/Banners/BannerThree";
import { CounterAreaTwo } from "../../components/CounterAreas/CounterAreaTwo";
import { CtaAreaTwo } from "../../components/CtaAreas/CtaAreaTwo";
import { FeatureThree } from "../../components/Features/FeatureThree";
import { OverviewAreaTwo } from "../../components/OverviewAreas/OverviewAreaTwo";

import { TestimonialAreaOne } from "../../components/TestimonialAreas/TestimonialAreaOne";
import { Layout } from "../../layouts/Layout";

const HomeTwo = () => {
  return (
    <Layout header={3} footer={3}>
      {/* banner-area */}
      <BannerThree />

      {/* brand-area 
      <BrandTwo title={"Trusted by 10,000+ companies around the world"} />*/}

      {/* features-area */}
      <FeatureThree />

      {/* about-area */}
      <AboutFour />

      {/* overview-area */}
      <OverviewAreaTwo />

      {/* project-area
      <ProjectAreaThree /> */}

      {/* counter-area */}
      <CounterAreaTwo />

      {/* testimonial-area */}
      <TestimonialAreaOne />

      {/* cta-area */}
      <CtaAreaTwo />

      {/* blog-area 
      <BlogAreaThree />*/}
    </Layout>
  );
};

export default HomeTwo;
