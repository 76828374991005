import React from "react";
import { Layout } from "../../layouts/Layout";
import { AboutFour } from "../../components/About/AboutFour";
import { FeatureFive } from "../../components/Features/FeatureFive";
import { TeamAreaThree } from "../../components/TeamAreas/TeamAreaThree";
import { TestimonialAreaOne } from "../../components/TestimonialAreas/TestimonialAreaOne";

const AboutPage = () => {
  return (
    <Layout breadcrumb={"Nosotros"} title={"Sobre Nosotros"} header={4} footer={3}>
      {/* about-area */}
      <AboutFour />

      {/* features-area */}
      <FeatureFive />

      {/* team-area */}
      <TeamAreaThree />

      {/* testimonial-area  */}
      <TestimonialAreaOne />

  
    </Layout>
  );
};

export default AboutPage;
