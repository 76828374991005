import React from "react";
import { ScrollToTopButton } from "../components/ScrollToTopButton/ScrollToTopButton";
import { HeaderThree } from "../components/Headers/HeaderThree";
import { HeaderFour } from "../components/Headers/HeaderFour";
import { FooterThree } from "../components/Footers/FooterThree";
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb";

export const Layout = ({ children, header, footer, breadcrumb, title }) => {
  return (
    <>
      {/* Scroll-top  */}
      <ScrollToTopButton />

      {/* header */}
      
      {(!header || header === 3) && <HeaderThree />}
      {header === 4 && <HeaderFour />}
     
      

      {/* breadcrumb */}
      {breadcrumb && <Breadcrumb text={breadcrumb} title={title} />}

      {/* children */}
      <main className="fix">{children}</main>

      {/* footer */}
      {(!footer || footer === 3) && <FooterThree />}

    </>
  );
};
