import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-investment",
    title: "Finance Investment",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Advisory",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "Asesoría Fiscal Personalizada",
    subtitle:
      "Recibe asesoramiento especializado adaptado a tus necesidades individuales y empresariales, garantizando estrategias fiscales eficientes y efectivas.",
    href: "/contact",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "Preparación de Impuestos",
    subtitle:
      "Nos encargamos de la preparación completa de tus declaraciones de impuestos, asegurando precisión y cumplimiento con las normativas vigentes.",
    href: "/contact",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Cumplimiento Normativo",
    subtitle:
      "Mantente al día con todas las regulaciones fiscales de EE.UU. gracias a nuestro equipo experto en normativas y leyes tributarias.",
    href: "/contact",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "Optimización Fiscal",
    subtitle:
      "Descubre oportunidades para maximizar tus beneficios fiscales y minimizar tus obligaciones tributarias con nuestras soluciones estratégicas.",
    href: "/contact",
    src: RIGHT_ARROW,
  }
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-life-insurance",
    title: "More coverage",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Less hassle",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    title: "Faster benefits",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Asesoría Fiscal Personalizada de TAX",
    subtitle: "Recibe asesoramiento especializado en TAX adaptado a tus necesidades individuales y empresariales, garantizando estrategias fiscales eficientes y efectivas.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Preparación de TAXES",
    subtitle: "Nos encargamos de la preparación completa de tus declaraciones de TAXES, asegurando precisión y cumplimiento con las normativas vigentes.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Cumplimiento Normativo de TAXES",
    subtitle: " Mantente al día con todas las regulaciones fiscales de EE.UU. gracias a nuestro equipo experto en normativas y leyes de TAXES.",
  },
];
