import React from "react";
import {
  H3_ABOUT_IMG01,
  H3_ABOUT_IMG02,
  H3_ABOUT_SHAPE01,
  H3_ABOUT_SHAPE02,
  H3_ABOUT_SHAPE03,
} from "../../lib/assets";

export const AboutFour = () => {
  return (
    <section className="about-area-four pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-7 col-md-10 order-0 order-lg-2">
            <div className="about-img-wrap-four">
              <div className="mask-img-wrap">
                <img src={H3_ABOUT_IMG01} alt="" />
              </div>
              <div className="icon">
                <i className="flaticon-business-presentation"></i>
              </div>
              <img src={H3_ABOUT_IMG02} alt="" className="img-two" />
              <div className="about-shape-wrap-three">
                <img src={H3_ABOUT_SHAPE01} alt="" />
                <img src={H3_ABOUT_SHAPE02} alt="" />
                <img src={H3_ABOUT_SHAPE03} alt="" />
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="about-content-four">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style1">
                <span className="sub-title tg-element-title">
                Sobre Nosotros


                </span>
                <h2 className="title tg-element-title">
                Liderando con Excelencia en Gestión Fiscal</h2>
              </div>
              <p>
              GNC Income Tax se dedica a proporcionar servicios de gestión legal de impuestos a nivel nacional en los Estados Unidos. Con una sólida trayectoria y un equipo de profesionales altamente capacitados, nos especializamos en ofrecer soluciones integrales y personalizadas para cumplir con todas tus obligaciones fiscales. Ubicados en Kissimmee, Florida, estamos certificados bajo los lineamientos de las autoridades fiscales de EE.UU., lo que nos permite garantizar un servicio confiable y preciso. Nuestra misión es ayudarte a navegar el complejo panorama fiscal, asegurando que cumplas con todas las normativas y optimices tu situación tributaria. En GNC Income Tax, tu tranquilidad es nuestra prioridad.
              </p>
              <div className="about-list-three">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-profit"></i>
                    </div>
                    <div className="content">
                      <h2 className="title">Mejora tus TAXES</h2>
                      <p>
                        Estamos para guiarlos en este arduo proceso de impuestos.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-mission"></i>
                    </div>
                    <div className="content">
                      <h2 className="title">Objetivos</h2>
                      <p>
                        Nuestro objetivo es ayudarte a cumplir con tus obligaciones fiscales.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
