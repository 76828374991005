import React from "react";
import { W_LOGO } from "../../lib/assets";
import { Link } from "react-router-dom";

export const FooterThree = () => {
  return (
    <footer>
      <div className="footer-area-three">
        <div className="footer-top-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <Link to="/">
                      <img src={W_LOGO} alt="" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                    En GNC Income Tax, somos una empresa comprometida con brindar soluciones integrales en la gestión legal de impuestos en los Estados Unidos. Con años de experiencia y un equipo altamente capacitado, nos enorgullece ofrecer servicios de calidad que aseguran el cumplimiento de todas las normativas fiscales vigentes.
                    </p>
                    <div className="footer-social footer-social-three">
                      <ul className="list-wrap">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Mapa del sitio</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/">Inicio</Link>
                      </li>
                      <li>
                        <Link to="/about">Nosostros</Link>
                      </li>
                      <li>
                        <Link to="/services">Servicios</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contáctanos</Link>
                      </li>
                      <li>
                        <Link to="/contact">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Contáctanos</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>1210 E Osceola Pkwy Suite 301, Kissimmee, FL 34744</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <a href="tel:14077172336">+1 (407) 717-2336</a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            Lun – Sáb: 8 am – 5 pm, <br /> Domingos:{" "}
                            <span>CLOSED</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text-two text-center">
                  <p>Copyright © GNC Income TAX | All Right Reserved 
                    | DEV by <a href="https://digitalesweb.com" target="_blank" rel="noreferrer" >DigitalesWeb</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
