import React from "react";
import { CONTACT_IMG } from "../../lib/assets";

export const ContactAreaInner = () => {
  return (
    <section className="inner-contact-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="inner-contact-img">
              <img src={CONTACT_IMG} alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="inner-contact-info">
              <h2 className="title">Dirección</h2>
              <div className="contact-info-item">
                <h5 className="title-two">Oficina EE.UU.</h5>
                <ul className="list-wrap">
                  <li>
                  1210 E Osceola Pkwy Suite 301, Kissimmee, FL 34744
                  </li>
                  <li><a href="tel:14077172336">+1 (407) 717-2336</a></li>
                  <li><a href="mailto:info@gncincometax.com"> info@gncincometax.com</a></li>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
